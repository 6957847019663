/* #region  Home config */
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.ck p {
  color: #000;
}

.button-hover {
  transition: all 0.3s ease;
}
.button-hover:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffae00;
  color: #000;
}

.customInput {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  background: #272625;
  border-color: #625b4b;
  color: white;
}

/* #endregion */
/* #region  Language */
.language-link {
  color: white;
  cursor: pointer;
  margin-right: 10px;
  transition: color 0.3s ease;
  font-size: 16px;
}

.language-link:hover {
  text-decoration: underline;
  transform: scale(1.1);
}

.arabic-text * {
  font-size: 1rem !important;
}

/* #endregion */
/* #region  Pace */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #ba3636;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 5px;
}

/* #endregion */
/* #region  Scrollbar */
::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
  background-color: #333; /* Set the background color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #555; /* Set the color of the thumb */
  border-radius: 5px; /* Add some border-radius for a rounded look */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* Change the color on hover */
}

/* #endregion *//*# sourceMappingURL=establishmentStyle.css.map */