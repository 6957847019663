/* #region  Pace */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #3689ba;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 10px;
}

/* #endregion */
/* #region  Transitions */
.page {
  animation: fadeIn 3s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* #endregion */
.addButton {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
  background: #cda45e;
  border: 0;
  padding: 10px 35px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
  display: block;
  margin: 1rem;
}

.full-page-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* #region  scaleUpDown animation */
.animated-element {
  animation: scaleUpDown 1s linear infinite; /* Adjust the duration and timing function as needed */
}

@keyframes scaleUpDown {
  0%, 100% {
    transform: scale(1); /* Start and end position */
  }
  50% {
    transform: scale(1.1); /* Middle position, adjust the distance as needed */
  }
}
/* #endregion *//*# sourceMappingURL=dashboard.css.map */