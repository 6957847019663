.addButton {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  cursor: pointer;
  background: #cda45e;
  border: 0;
  padding: 10px 20px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
  display: block;
  margin: 1rem 0;
  
}

.button-hover {
  transition: all 0.3s ease;
}

.button-hover:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffae00;
  color: #000;
}