@import './sweetAlertThemes/sweetAlertDarkTheme.css';

.MuiFormControl-root label{
  background-color: #fff;
}
.pointerCursor {
  cursor: pointer;
}
.centerButton {
  display: flex;
  justify-content: center;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.dropImage {
  height: 100px !important;
  // input {
  //   color: white;
  // }
}

button:disabled {
  background-color: #cccccc; /* Light gray */
  color: #666666; /* Dark gray */
  cursor: not-allowed;

  &:hover {
    background-color: #cccccc; /* Light gray */
    color: #666666; /* Dark gray */
    cursor: not-allowed;
  }
}

.swal2-container {
  z-index: 10000;
}

.root {
  position: relative;
}

hr {
  width: 100%;
}

.iconAndLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}

@mixin hover-effect($scale: 1, $brightness: 1.1, $color: white) {
  transition: all 0.5s;
  &:hover {
    filter: brightness($brightness);
    transform: scale($scale);
    // color: $color !important;
    // * {
    //   color: $color !important;
    // }
  }
}

.custom-hover {
  @include hover-effect(1.05, 1.1);
  &:hover {
    background-color: #0073bf;

    * {
      color: white;
    }
  }
}

.custom-hover-05 {
  @include hover-effect(1.05);
}

.custom-hover-10 {
  @include hover-effect(1.1);
}

.custom-hover-15 {
  @include hover-effect(1.15);
}

.custom-hover-20 {
  @include hover-effect(1.2);
}

.text-hover-1 {
  @include hover-effect(1.02);
}

.text-hover-2 {
  @include hover-effect(1.1);
}

.title-hover-1 {
  transition: all 0.5s;
  transform-origin: left;
  &:hover {
    color: white !important;
    filter: brightness(1.1);
    transform: scale(1.05);
  }
}

.title-hover-2 {
  transition: all 0.5s;
  transform-origin: left;
  &:hover {
    filter: brightness(1.1);
    transform: scale(1.15);
  }
}
