@import './sweetAlertThemes/sweetAlertDarkTheme.css';
.MuiFormControl-root label {
  background-color: #fff;
}

.pointerCursor {
  cursor: pointer;
}

.centerButton {
  display: flex;
  justify-content: center;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.dropImage {
  height: 100px !important;
}

button:disabled {
  background-color: #cccccc; /* Light gray */
  color: #666666; /* Dark gray */
  cursor: not-allowed;
}
button:disabled:hover {
  background-color: #cccccc; /* Light gray */
  color: #666666; /* Dark gray */
  cursor: not-allowed;
}

.swal2-container {
  z-index: 10000;
}

.root {
  position: relative;
}

hr {
  width: 100%;
}

.iconAndLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-hover {
  transition: all 0.5s;
}
.custom-hover:hover {
  filter: brightness(1.1);
  transform: scale(1.05);
}
.custom-hover:hover {
  background-color: #0073bf;
}
.custom-hover:hover * {
  color: white;
}

.custom-hover-05 {
  transition: all 0.5s;
}
.custom-hover-05:hover {
  filter: brightness(1.1);
  transform: scale(1.05);
}

.custom-hover-10 {
  transition: all 0.5s;
}
.custom-hover-10:hover {
  filter: brightness(1.1);
  transform: scale(1.1);
}

.custom-hover-15 {
  transition: all 0.5s;
}
.custom-hover-15:hover {
  filter: brightness(1.1);
  transform: scale(1.15);
}

.custom-hover-20 {
  transition: all 0.5s;
}
.custom-hover-20:hover {
  filter: brightness(1.1);
  transform: scale(1.2);
}

.text-hover-1 {
  transition: all 0.5s;
}
.text-hover-1:hover {
  filter: brightness(1.1);
  transform: scale(1.02);
}

.text-hover-2 {
  transition: all 0.5s;
}
.text-hover-2:hover {
  filter: brightness(1.1);
  transform: scale(1.1);
}

.title-hover-1 {
  transition: all 0.5s;
  transform-origin: left;
}
.title-hover-1:hover {
  color: white !important;
  filter: brightness(1.1);
  transform: scale(1.05);
}

.title-hover-2 {
  transition: all 0.5s;
  transform-origin: left;
}
.title-hover-2:hover {
  filter: brightness(1.1);
  transform: scale(1.15);
}/*# sourceMappingURL=app.css.map */